import { PUBLIC_LANDING_PAGE_URL } from '$env/static/public';
import type { SubscriptionPlan } from '$stores/subscription/types';

export const SUBSCRIPTION_PLAN_ORDER: SubscriptionPlan[] = [
  'free',
  'mini',
  'starter',
  'creator',
  'pro'
];

// We're using base-2 mebi/gibi-bytes here, but we're displaying the limits in the UI
// as normal MB/GB. This permits uploads that are actually a bit bigger than the displayed
// limit, so that a file shown by Windows (which uses base-2) to be say 1.49GB will still
// go through.
export const MAX_UPLOAD_SIZE = {
  free: 100 * 1024 * 1024, // 100 MiB
  mini: 800 * 1024 * 1024, // 800 MiB
  starter: 1.5 * 1024 * 1024 * 1024, // 1.5 GiB
  creator: 1.5 * 1024 * 1024 * 1024, // 1.5 GiB
  pro: 1.5 * 1024 * 1024 * 1024 // 1.5 GiB
};

// in seconds
export const MAX_UPLOAD_DURATION = {
  free: 10 * 60,
  mini: 60 * 60,
  starter: 120 * 60,
  creator: 120 * 60,
  pro: 120 * 60
};

export const JOB_COMPLETION_POLLING_INTERVAL = 10; // seconds

export const AUDIO_DOWNLOAD_THRESHOLD = 30 * 60; // seconds

export const DATA_POLICY_URL = PUBLIC_LANDING_PAGE_URL + 'privacy/';
export const TERMS_URL = PUBLIC_LANDING_PAGE_URL + 'terms/';
export const PRICING_URL = PUBLIC_LANDING_PAGE_URL + 'pricing/';

export const ALLOWED_FILE_TYPES = [
  'audio/vnd.wave',
  'audio/wav',
  'audio/wave',
  'audio/x-wav',
  'audio/mpeg',
  'audio/MPA',
  'audio/mpa-robust',
  'audio/mp3',
  'audio/ogg',
  'audio/opus',
  'audio/m4a',
  'audio/x-m4a',
  'audio/x-aiff',
  'audio/aiff',
  'video/mp4',
  'video/mpeg',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-ms-wmv',
  'video/webm'
];

export const SENTRY_DSN =
  'https://2ade342bf10e02d57efe43d4025a2bef@o4507684538548224.ingest.de.sentry.io/4507929198067792';
